var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
function Title(props) {
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "component-title" }, { children: [_jsxs("div", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: [_jsxs("h3", __assign({ style: { color: 'black' } }, { children: [props.text, " "] })), props.variableText && (_jsx("h3", __assign({ style: props.style }, { children: props.variableText })))] })), props.hr === false ? "" : _jsx("hr", { style: { marginTop: "5px", marginBottom: "1rem", border: "0.05rem solid #d4d3d3" } })] })) }));
}
export default Title;
