var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Link } from 'react-router-dom';
import "../../App/styles/GlobalStyle.css";
import "../styles/BillingDetail.css";
import Modal from 'react-bootstrap/Modal';
function ModalInvoice(props) {
    var _a = useState('pdf'), selectedFormat = _a[0], setSelectedFormat = _a[1];
    function handleSelectChange(event) {
        setSelectedFormat(event.target.value);
    }
    ;
    function getDownloadLink() {
        if (selectedFormat === 'pdf') {
            return props.nfse_link;
        }
        else if (selectedFormat === 'xml') {
            return props.xml_link;
        }
        return "";
    }
    return (_jsx(_Fragment, { children: _jsxs(Modal, __assign({}, props, { "aria-labelledby": "contained-modal-title-vcenter", centered: true, className: "default-modal-styling" }, { children: [_jsx(Modal.Header, { children: _jsx("h3", { children: "Nota Fiscal" }) }), _jsxs(Modal.Body, { children: [_jsx("p", { children: "Seleciona o formato do arquivo que deseja baixar" }), _jsxs("select", __assign({ className: "billing-select", onChange: handleSelectChange }, { children: [_jsx("option", __assign({ value: "pdf" }, { children: "Nota fiscal PDF" })), _jsx("option", __assign({ value: "xml" }, { children: "Nota fiscal XML" }))] }))] }), _jsxs(Modal.Footer, { children: [_jsx("button", __assign({ className: "button-with-blue-border", style: { width: "106px", height: "36px" }, onClick: props.closeModal }, { children: "Cancelar" })), _jsx(Link, __assign({ to: getDownloadLink(), target: "_blank", className: "blue-button", style: { width: "72px", height: "36px", display: "flex", alignItems: "center", justifyContent: "center" } }, { children: "Baixar" }))] })] })) }));
}
export default ModalInvoice;
