import { api } from "../../App/services/api";
function handlePromotion(identifier, token) {
    return api.get("/promo/".concat(identifier, "/").concat(token));
}
function getCustomerPromotionTokens() {
    return api.get("/promos");
}
export default {
    handlePromotion: handlePromotion,
    getCustomerPromotionTokens: getCustomerPromotionTokens
};
