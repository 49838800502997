var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import SubscriberService from '../services/SubscriberService';
import { dateFormatter, documentFormatter } from '../../App/utils/formatter';
import { Pagination } from '@mui/material';
import Notification from "../../App/components/Notification";
import { handleServiceError } from "../../App/components/ErrorHandler";
import { useForm } from "react-hook-form";
import { useSearchParams } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { useCustomer } from "../../App/hooks/useCustomer";
function MeliuzList(props) {
    var _this = this;
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit;
    var _b = useSearchParams(), searchParams = _b[0], setSearchParams = _b[1];
    var _c = useState(""), searchMeliuz = _c[0], setSearchMeliuz = _c[1];
    var _d = useState([]), meliuzSubscribers = _d[0], setMeliuzSubscribers = _d[1];
    var _e = useState(1), currentPage = _e[0], setCurrentPage = _e[1];
    var _f = useState(0), totalItems = _f[0], setTotalItems = _f[1];
    var _g = useState(20), perPage = _g[0], setPerPage = _g[1];
    var _h = useState([]), orderBy = _h[0], setOrderBy = _h[1];
    var _j = useState(false), hasMeliuzFetched = _j[0], setHasMeliuzFetched = _j[1];
    var customer = useCustomer().customer;
    useEffect(function () {
        if (!hasMeliuzFetched) {
            getAllMeliuz(currentPage, perPage, orderBy);
            setHasMeliuzFetched(true);
        }
    }, [props.selectedSupplierId, hasMeliuzFetched, props.contentSuppliers]);
    function getAllMeliuz(page, perPage, orderBy) {
        if (page === void 0) { page = 1; }
        if (perPage === void 0) { perPage = 20; }
        if (orderBy === void 0) { orderBy = []; }
        return __awaiter(this, void 0, void 0, function () {
            var res, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Block.circle("[data-id=meliuz-grid]");
                        if (!(searchMeliuz === "")) return [3 /*break*/, 6];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, SubscriberService.getAllDocumentsByCustomerId(page, perPage, orderBy)];
                    case 2:
                        res = _a.sent();
                        setMeliuzSubscribers(res.data.data);
                        setTotalItems(res.data.total);
                        Notification().Block.remove("[data-id=meliuz-grid]");
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        handleServiceError(error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        Notification().Block.remove("[data-id=meliuz-grid]");
                        return [7 /*endfinally*/];
                    case 5: return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, handleSearchMeliuz({ term: searchMeliuz })];
                    case 7:
                        _a.sent();
                        _a.label = 8;
                    case 8: return [2 /*return*/];
                }
            });
        });
    }
    ;
    function handleSearchMeliuz(data) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Block.circle("[data-id=meliuz-grid]");
                        return [4 /*yield*/, SubscriberService.searchDocumentsByCustomerId({ search_value: searchMeliuz, limit: perPage, order_by: orderBy }).then(function (res) {
                                setSearchMeliuz(searchMeliuz);
                                setMeliuzSubscribers(res.data.data);
                                Notification().Block.remove("[data-id=meliuz-grid]");
                            }, function (err) {
                                Notification().Block.remove("[data-id=meliuz-grid]");
                                if (err.response.status === 403) {
                                    Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
                                }
                                else {
                                    Notification().Notify.failure(err.message);
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    var handleFilterTextMeliuz = function (event) {
        setSearchMeliuz(event.target.value);
    };
    function cleanFilterMeliuz() {
        return __awaiter(this, void 0, void 0, function () {
            var meliuzResponse, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Block.circle("[data-id=meliuz-grid]");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, SubscriberService.getAllDocumentsByCustomerId(currentPage, perPage, orderBy)];
                    case 2:
                        meliuzResponse = _a.sent();
                        setSearchMeliuz("");
                        setSearchParams("");
                        setMeliuzSubscribers(meliuzResponse.data.data);
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        if (err_1.response && err_1.response.status === 403) {
                            Notification().Notify.failure("Usuário não tem permissão para visualizar essa página!");
                        }
                        else {
                            Notification().Notify.failure(err_1.message);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        Notification().Block.remove("[data-id=meliuz-grid]");
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    var handlePageChange = function (event, value) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (value > Math.ceil(totalItems / perPage)) {
                return [2 /*return*/];
            }
            Notification().Block.circle("[data-id=meliuz-grid]");
            setCurrentPage(value);
            getAllMeliuz(value, perPage).finally(function () {
                Notification().Block.remove("[data-id=meliuz-grid]");
            });
            return [2 /*return*/];
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "action-container-for-subscriber-management" }, { children: _jsx("div", __assign({ className: "search-subscribers" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(handleSearchMeliuz) }, { children: [_jsx("button", __assign({ type: "submit" }, { children: _jsx(SearchRoundedIcon, {}) })), _jsx("input", __assign({}, register("term"), { required: true, type: "text", placeholder: "Busca por assinante", "aria-label": "Busca por assinante", "aria-describedby": "button-addon2", onChange: handleFilterTextMeliuz, value: searchMeliuz })), searchMeliuz && (_jsx("button", __assign({ type: "button", onClick: cleanFilterMeliuz }, { children: _jsx(ClearIcon, {}) })))] })) })) })), customer.meliuz_plan_id !== null ?
                (_jsxs("table", __assign({ className: "default-table-styling", "data-id": "meliuz-grid" }, { children: [_jsxs("tr", { children: [_jsx("th", { children: "CPF" }), _jsx("th", { children: "E-mail" }), _jsx("th", { children: "Data de Cadastro" })] }), meliuzSubscribers.length > 0 ? (meliuzSubscribers.map(function (item, index) {
                            return (_jsxs("tr", { children: [_jsx("td", { children: documentFormatter(item.document) }), _jsx("td", { children: item.email }), item.created_at === null ?
                                        _jsx("td", { children: "Sem data" }) : _jsx("td", { children: dateFormatter(item.created_at) })] }, index));
                        })) : (_jsx("tr", { children: _jsx("td", __assign({ colSpan: 4, style: { textAlign: 'center', padding: '1rem' } }, { children: "N\u00E3o h\u00E1 nenhum assinante." })) }))] }))) : (_jsx("div", __assign({ style: { display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#fff", marginTop: "2rem", padding: "1rem", borderRadius: "8px" } }, { children: _jsx("p", __assign({ className: "mb-0" }, { children: "Voc\u00EA ainda n\u00E3o faz parte da M\u00E9liuz, entre em contato com o seu CS para adquirir esse novo produto." })) }))), _jsx("div", __assign({ style: { display: "flex", justifyContent: "flex-end", paddingTop: "1rem" } }, { children: _jsx(Pagination, { count: Math.ceil(totalItems / perPage), page: currentPage, onChange: handlePageChange, color: "standard", sx: {
                        '& .MuiPaginationItem-root': {
                            color: '#ff8926',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            backgroundColor: '#ff8926',
                            color: '#fff',
                        },
                        '& .MuiPaginationItem-root:hover': {
                            backgroundColor: '#ff8926',
                            color: '#fff',
                        }
                    }, showFirstButton: true, showLastButton: true }) }))] }));
}
export default MeliuzList;
