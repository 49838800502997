var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Can } from "../../App/utils/Authorize";
import useContentSuppliers from "../../App/hooks/useContentSuppliers";
import { handleServiceError } from "../../App/components/ErrorHandler";
import ExportSubscribersButton from "../components/ExportSubscribersButton";
import MeliuzList from "../components/MeliuzList";
import Title from "../../App/components/Title";
import Notification from "../../App/components/Notification";
import Breadcrumbs from "../../App/components/Breadcrumbs";
import "../styles/Subscribers.css";
import ContentSuppliersTabs from "../../App/components/ContentSuppliersTabs";
import GloboList from "../components/GloboList";
function Subscribers() {
    var _this = this;
    var _a = useState(undefined), selectService = _a[0], setSelectService = _a[1];
    var _b = useState(undefined), selectedSupplierId = _b[0], setSelectedSupplierId = _b[1];
    var _c = useContentSuppliers(), contentSuppliers = _c.contentSuppliers, getAllContentGetSuppliersWithProducts = _c.getAllContentGetSuppliersWithProducts;
    var hasFetchedRef = useRef(false);
    useEffect(function () {
        if (!hasFetchedRef.current) {
            getAllContentGetSuppliersWithProducts();
            hasFetchedRef.current = true;
        }
    }, [getAllContentGetSuppliersWithProducts]);
    function renderTab() {
        switch (selectedSupplierId) {
            case 1:
                return (_jsx(_Fragment, { children: _jsx(GloboList, { contentSuppliers: contentSuppliers, selectService: selectService, selectedSupplierId: selectedSupplierId }) }));
            case 2:
                return (_jsx(_Fragment, { children: _jsx(MeliuzList, { contentSuppliers: contentSuppliers, selectedSupplierId: selectedSupplierId }) }));
        }
    }
    useEffect(function () {
        var selectedService = localStorage.getItem('selectedService');
        var serviceId = selectedService ? parseInt(selectedService) : undefined;
        if (!selectedSupplierId && !selectService && contentSuppliers.length > 0) {
            var initialSupplierId = serviceId || contentSuppliers[0].id;
            setSelectedSupplierId(initialSupplierId);
            setSelectService(initialSupplierId);
        }
    }, [selectedSupplierId, selectService, contentSuppliers]);
    var handleSupplierChange = function (supplierId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSelectService(supplierId);
            setSelectedSupplierId(supplierId);
            Notification().Block.circle("[data-id=subscribers-grid]");
            localStorage.setItem('selectedService', supplierId.toString());
            if (supplierId) {
                try {
                    Notification().Block.remove("[data-id=subscribers-grid]");
                }
                catch (error) {
                    handleServiceError(error);
                }
            }
            else {
                setSelectService(undefined);
                Notification().Block.remove("[data-id=subscribers-grid]");
            }
            return [2 /*return*/];
        });
    }); };
    var breadcrumbsItens = [
        {
            name: "Gerenciar assinantes",
            route: "/subscribers"
        }
    ];
    return (_jsxs("div", __assign({ className: "content-internal-padding" }, { children: [_jsx(ContentSuppliersTabs, { contentSuppliers: contentSuppliers, onSupplierChange: handleSupplierChange, selectedSupplierId: selectedSupplierId }), _jsxs("div", __assign({ className: "d-flex justify-content-between" }, { children: [_jsxs("div", { children: [_jsx(Title, { text: "Gerenciar assinantes", hr: false }), _jsx(Breadcrumbs, { itens: breadcrumbsItens })] }), _jsx("div", __assign({ style: { fontSize: '30px' } }, { children: _jsx(Can, __assign({ permission: "export-subscriber" }, { children: _jsx(ExportSubscribersButton, {}) })) }))] })), renderTab()] })));
}
export default Subscribers;
