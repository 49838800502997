var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BillingService from "../services/BillingService";
import { Can } from "../../App/utils/Authorize";
import { formatCurrencyValues } from "../../App/utils/formatter";
import Title from "../../App/components/Title";
import Breadcrumbs from "../../App/components/Breadcrumbs";
import Notification from "../../App/components/Notification";
import "../styles/BillingDetailReport.css";
import "../styles/Billing.css";
import "../../App/styles/GlobalStyle.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import PaidIcon from "@mui/icons-material/Paid";
function BillingDetailReport() {
    var location = useLocation();
    var _a = useState([]), billingDetailReportData = _a[0], setBillingDetailReportData = _a[1];
    var _b = useState(0), total = _b[0], setTotal = _b[1];
    useEffect(function () {
        getBillingDetailReport();
    }, []);
    function getBillingDetailReport() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                BillingService.report(location.state.billingDetails.billing_id, location.state.billingDetails.token_id).then(function (res) {
                    setBillingDetailReportData(res.data.billing_details.data);
                }, function (err) {
                    Notification().Loading.remove();
                    if (err.response.status === 403) {
                        Notification().Notify.failure("Usuário não tem permissão para visualizar essa página!");
                    }
                    else {
                        Notification().Notify.failure(err.message);
                    }
                });
                return [2 /*return*/];
            });
        });
    }
    ;
    function handleExport() {
        return __awaiter(this, void 0, void 0, function () {
            var updatePrice;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updatePrice = billingDetailReportData;
                        updatePrice.forEach(function (res) {
                            if (res.need_override === 1) {
                                if (res && res.discount) {
                                    res.price = (res.discount * -(1 - res.margin / 100));
                                }
                            }
                            else {
                                res.price = (res.price * (1 - res.margin / 100));
                            }
                        });
                        return [4 /*yield*/, BillingService.exportMany(__spreadArray([], updatePrice, true)).then(function (res) {
                                var file = new Blob(["\uFEFF", res.data], { type: "text/csv" });
                                var url = window.URL.createObjectURL(file);
                                var tempLink = document.createElement("a");
                                tempLink.href = url;
                                tempLink.setAttribute("download", "minhas-faturas.csv");
                                tempLink.click();
                            }, function (err) {
                                if (err.response.status === 403) {
                                    Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    function getPriceThatShouldShow(reportData) {
        if (reportData.need_override === 1) {
            if (reportData && reportData.discount) {
                return (reportData.discount * -(1 - reportData.margin / 100));
            }
        }
        else {
            return ((reportData.price * (1 - reportData.margin / 100)));
        }
    }
    ;
    function calculateTotal() {
        return __awaiter(this, void 0, void 0, function () {
            var total;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(billingDetailReportData.map(function (currentItem) { return __awaiter(_this, void 0, void 0, function () {
                            var price;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, getPriceThatShouldShow(currentItem)];
                                    case 1:
                                        price = _a.sent();
                                        return [2 /*return*/, Number(price)];
                                }
                            });
                        }); })).then(function (values) { return values.reduce(function (total, value) { return total + value; }, 0); })];
                    case 1:
                        total = _a.sent();
                        return [2 /*return*/, total];
                }
            });
        });
    }
    ;
    calculateTotal().then(function (total) { return setTotal(total); });
    function discount(margin) {
        if (margin) {
            var discount_1 = 1 - margin / 100;
            return discount_1;
        }
        return 1;
    }
    ;
    var breadcrumbsItens = [
        {
            name: "Minhas faturas",
            route: "/billings"
        },
        {
            name: "Detalhes da fatura",
            route: "/billings/".concat(location.state.billingDetails.billing_id)
        },
        {
            name: "Relat\u00F3rio de cobran\u00E7a do assinante ".concat(location.state.billingDetails.subscriber_name),
            route: "/billings/".concat(location.state.billingDetails.billing_id, "/report")
        }
    ];
    return (_jsxs("div", __assign({ className: "content-internal-padding" }, { children: [_jsxs("div", __assign({ className: "justify-content-between d-flex" }, { children: [_jsx(Title, { text: "Relat\u00F3rio de cobran\u00E7a do assinante ".concat(location.state.billingDetails.subscriber_name), hr: false }), _jsx(Can, __assign({ permission: "export-tokenBillingReport" }, { children: _jsx("button", __assign({ onClick: handleExport, type: "button", id: "button-addon2", style: { border: "none", color: "003981", backgroundColor: 'transparent' } }, { children: _jsx(FileUploadOutlinedIcon, { style: { color: "#003981" } }) })) }))] })), _jsx(Breadcrumbs, { itens: breadcrumbsItens }), _jsxs("table", __assign({ className: "default-table-styling" }, { children: [_jsxs("tr", { children: [_jsx("th", { children: "Atividade" }), _jsx("th", { children: "Servi\u00E7o" }), _jsx("th", { children: "Dias em uso" }), _jsx("th", { children: "Data de In\u00EDcio" }), _jsx("th", { children: "Data final" }), _jsx("th", { children: "Valor" })] }), billingDetailReportData.length > 0 ? (billingDetailReportData.map(function (reportData, key) { return (_jsxs("tr", { children: [_jsx("td", __assign({ className: "d-flex align-items-center justify-content-center" }, { children: _jsx("div", __assign({ style: { background: reportData.token_status_color, color: "#fff", marginRight: "0" }, className: "billing-status" }, { children: reportData.token_status_translation })) })), _jsx("td", { children: reportData.content_supplier_product_name }), _jsx("td", { children: reportData.days_in_use }), _jsx("td", { children: new Date(reportData.started_at).toLocaleDateString() }), _jsx("td", { children: (function () {
                                    var started_at = reportData.started_at;
                                    var endedAt = reportData.ended_at;
                                    var date = new Date(reportData.ended_at);
                                    if (started_at != endedAt) {
                                        date.setDate(date.getDate() - 1);
                                        return date.toLocaleDateString();
                                    }
                                    else {
                                        return new Date(reportData.ended_at).toLocaleDateString();
                                    }
                                })() }), _jsx("td", { children: new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(getPriceThatShouldShow(reportData) || 0) })] }, key)); })) : (_jsx("tr", { children: _jsx("td", __assign({ className: "text-center", colSpan: 6 }, { children: "Nenhum dado a ser exibido" })) }))] })), _jsx("table", __assign({ className: "default-table-styling" }, { children: _jsxs("tr", __assign({ className: "d-flex justify-content-between", style: { paddingInline: "2.5rem" } }, { children: [_jsxs("th", __assign({ className: "d-flex align-items-center gap-1" }, { children: [_jsx(PaidIcon, { className: "billing-paid" }), "Valor total"] })), _jsx("th", { children: formatCurrencyValues(total) })] })) }))] })));
}
export default BillingDetailReport;
